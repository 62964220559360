// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-business-index-js": () => import("./../../../src/pages/business/index.js" /* webpackChunkName: "component---src-pages-business-index-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-3-d-furniture-index-js": () => import("./../../../src/pages/projects/3d-furniture/index.js" /* webpackChunkName: "component---src-pages-projects-3-d-furniture-index-js" */),
  "component---src-pages-projects-euroviajar-index-js": () => import("./../../../src/pages/projects/euroviajar/index.js" /* webpackChunkName: "component---src-pages-projects-euroviajar-index-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-kalman-filter-js-index-js": () => import("./../../../src/pages/projects/kalman-filter-js/index.js" /* webpackChunkName: "component---src-pages-projects-kalman-filter-js-index-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

